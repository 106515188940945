@import-normalize; /* bring in normalize.css styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hd-link {
  text-decoration: none;
}

table.hd-mytable {
  border-spacing: 0;
  font-size: 14px;
}
table.hd-mytable td,
table.hd-mytable th {
  padding: 11px;
  text-align: left;
  vertical-align: top;
  padding-bottom: 6px;
  padding-top: 6px;
}
table.hd-mytable th {
  color: #182026;
  font-weight: 600;
}
table.hd-mytable td {
  color: #182026;
}
table.hd-mytable tbody tr:first-child td,
table.hd-mytable tbody tr:first-child th {
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
}

table.hd-mytable.striped tbody tr:nth-child(odd) td {
  background: rgba(191, 204, 214, 0.15);
}
table.hd-mytable.striped tbody tr:nth-child(odd) th {
  background: rgba(191, 204, 214, 0.15);
}

.bp3-callout h4 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
}
.bp3-callout h4 {
  font-size: 18px;
  line-height: 21px;
}
.bp3-callout .bp3-text-muted {
  color: #5c7080;
}
.bp3-callout :focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px;
}
.bp3-callout {
  font-size: 14px;
  line-height: 1.5;
  background-color: rgba(138, 155, 168, 0.15);
  border-radius: 3px;
  padding: 10px 12px 9px;
  position: relative;
  width: 100%;
}
.bp3-callout .bp3-heading {
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 0;
}
.bp3-callout .bp3-form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
}
.bp3-callout .bp3-form-group label.bp3-label {
  margin-bottom: 5px;
}
.bp3-callout .bp3-form-group .bp3-form-helper-text {
  color: #5c7080;
  font-size: 12px;
  margin-top: 5px;
}
.bp3-callout .bp3-input-group {
  display: block;
  position: relative;
}
.bp3-callout .bp3-input-group .bp3-input {
  position: relative;
  width: 100%;
}
.bp3-callout .bp3-input {
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
}
.bp3-callout .bp3-input::placeholder {
  color: rgba(92, 112, 128, 0.6);
  opacity: 1;
}
.bp3-callout .bp3-input:focus {
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.bp3-callout .bp3-input:disabled {
  background: rgba(206, 217, 224, 0.5);
  box-shadow: none;
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed;
  resize: none;
}
.bp3-callout .bp3-input::-ms-clear {
  display: none;
}
.bp3-callout label.bp3-label {
  display: block;
  margin-bottom: 15px;
  margin-top: 0;
}

.authDenied .authRequired {
  filter: blur(0.05px);
}

.authDenied .authEnforced {
  filter: blur(5px);
  pointer-events: none;
}
