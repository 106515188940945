body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#body {
  padding-top: 30px;
}

/* Bei Chrome die Tabs nicht doof umranden */
.bp3-tab {
  outline: none;
}

/* Alerts breit erlauben */
div.bp3-alert {
  width: auto;
  max-width: 100%;
  padding: 20px;
}
/* Alerts breit erlauben */
.bp3-alert-body {
  display: flex;
  overflow-x: auto;
}
