main.hd-main-content {
  margin: 20px;
  margin-top: 70px;
}

header.hd-nav .hd-navbar {
  color: #f5f8fa;
  background-color: #394b59;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.4);
  height: 50px;
  padding: 0 0px;
  width: 100vw;
  z-index: 10;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

header.hd-nav .hd-heading {
  font-size: 16px;
  margin-right: 15px;
}
header.hd-nav .hd-heading .ms-Icon {
  font-size: 150%;
  margin-right: 12px;
  vertical-align: middle;
}

header.hd-nav .hd-divider {
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  border-left-color: hsla(0, 0%, 100%, 0.15);
  color: rgb(245, 248, 250);
  height: 20px;
  margin: 0 10px;
}

header.hd-nav section {
  align-items: center;
  display: flex;
  height: 50px;
}

header.hd-nav section.hd-align-left button {
  text-align: left;
}
header.hd-nav section.hd-align-left {
  float: left;
  padding: 0 15px;
}
header.hd-nav section.hd-align-right button {
  text-align: right;
}
header.hd-nav section.hd-align-right {
  float: right;
}

header.hd-nav a {
  color: #f5f8fa;
  text-decoration: none;
}
header.hd-nav a:hover {
  color: #f5f8fa;
  text-decoration: none;
}

/* header.hd-nav button:before{margin-right:7px;}
header.hd-nav button:empty:before{margin-right:0;}
header.hd-nav button:empty{padding:0!important;}
 */

header.hd-nav button {
  background-color: #394b59;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.05), hsla(0, 0%, 100%, 0));
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  color: #f5f8fa;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px;
  background: none;
  box-shadow: none;
  color: inherit;
}

header.hd-nav button:active {
  background-color: #202b33;
  background-image: none;
  background: rgba(138, 155, 168, 0.3);
  box-shadow: none;
  color: #f5f8fa;
}
header.hd-nav button:hover {
  background-clip: padding-box;
  background-color: #30404d;
  background: rgba(138, 155, 168, 0.15);
  color: #f5f8fa;
  text-decoration: none;
}

header.hd-nav button > * {
  flex-grow: 0;
  flex-shrink: 0;
}

header.hd-nav button.hd-outlined {
  background: none;
  box-shadow: none;
  border: 1px solid rgba(24, 32, 38, 0.2);
  border-color: hsla(0, 0%, 100%, 0.4);
}
header.hd-nav button.hd-outlined:hover {
  background: rgba(167, 182, 194, 0.3);
  color: #182026;
  background-color: rgba(167, 182, 194, 0.3);
}

header.hd-nav button > .bp3-icon {
  color: #5c7080;
}
header.hd-nav button > span {
  flex: 0 1 auto;
  text-decoration: none;
}
header.hd-nav button > * {
  margin-right: 7px;
}
header.hd-nav button > :last-child {
  margin-right: 0;
}

header.hd-nav form {
  margin: 0 10px;
  font: inherit;
  color: inherit;
}

header.hd-nav form {
  line-height: normal;
}

header.hd-nav form input {
  display: block;
  width: 100%;
  height: 28px;
  padding: 0 6px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  display: inline-block;
  width: auto;
  vertical-align: middle;
}
header.hd-nav form input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
header.hd-nav form input::placeholder {
  color: #999;
  opacity: 1;
}
